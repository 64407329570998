import { Injectable } from '@angular/core';
import {Router , ActivatedRoute} from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import {ChangeDetectorRef } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders  } from '@angular/common/http';
//import { FormBuilder, Validators } from '@angular/forms';
/* import * as jQuery from 'jquery/dist/jquery.js';

import 'messenger/build/js/messenger.js'; */

 //declare let jQuery: any;
 /* declare let Messenger: any;

Messenger.options = {
    extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-right',
    theme: 'flat'
} */

declare var io : {

  };

  
@Injectable()
export class DataServiceService {
	isLogged : boolean;
	isLoggedin: boolean;
    AuthToken;
	userDetails: any; 
	costCenter : any;
	FY:any;
	costCenterId:any;
	FYID:any;
	 serverBase = 'https://demo2.mixins.in';
	//serverBase = 'http://eledash.herokuapp.com'; http://103.154.233.107:5004
	// APISERVERCONF.PROTOCOL + APISERVERCONF.HOST + ":"+APISERVERCONF.PORT;
	serverPath = this.serverBase ;
	serverBaceImage = this.serverBase + "/uploads/service/";
	
	validators:any;
	manufRoles:any=[];
	Roles:any=[];
	FYdet:any={
		FY_F:"",
		FY_T:""
	};
	pageItems;
	skipItems;
	dataApi;
	dataSession:any;
	key:any;
	iv:any;
	decryptdata:any={};
	userid:any;
	shopid:any;

  
	private url = 'http://185.185.126.229:5003';
	public test="test";
	private messageSource = new BehaviorSubject('default message');
	currentMessage = this.messageSource.asObservable();

	
	changeMessage(message: string) {
		this.messageSource.next(message)
	  }

	  
	

	

	private handleError(error) {
	//   console.error('server error:', error);
	  if (error.error instanceof Error) {
		  let errMessage = error.error.message;
		  return Observable.throw(errMessage);
	  }
	  return Observable.throw(error || 'Socket.io server error');
	}

    constructor(public http: HttpClient , public router: Router) {
		
	

        this.isLoggedin = false;
        this.AuthToken = null;
		this.isLogged =false;
	}

	getdate(){
		let FY_SD, FY_ED;
						
	}
	

	invalidForm = false;
	formSubmitted = false;
	submittedMessage = 'Form Submitted Successfully.';
	errorMessage = 'Please fill out all fields.';
	
	showFormAlert(){
		this.invalidForm = true;
		setTimeout(() => {
			this.invalidForm = false;
		}, 5000)
	}
	
    storeUserCredentials(token) {
		window.localStorage.setItem('token', token);
        this.useCredentials(token);
	}
    
    useCredentials(token) {
		this.isLoggedin = true;
        this.AuthToken = token;
		// this.userDetails = window.localStorage.getItem("userDetails") ? JSON.parse(window.localStorage.getItem("userDetails")) : null;
	}
    
    loadUserCredentials() {
		var token = window.sessionStorage.getItem('token');
        this.useCredentials(token);
	}
	
    destroyUserCredentials() {
        this.isLoggedin = false;
        this.AuthToken = null;
        window.localStorage.clear();
	}
    query ={
		
		
	}
    login(user) {
		
        return new Promise((resolve, reject) => {
			var params = { filter:{include : "role" }};
			var headers = new Headers();
            this.loadUserCredentials();
            
			if(!user.user || !user.password){
				return resolve("Email and Password Required.");
			}
			
			this.http.post(this.serverPath + '/userAuth', user).subscribe(data => {
				
				localStorage.setItem("userName", user.user);
				localStorage.setItem("costCenter", user.Branch);
				this.userDetails = localStorage.getItem("userName") || null;
				this.costCenter=localStorage.getItem("costCenter") || null;
				headers.append('Authorization', user.user);
				resolve(data);

				
			}, err => {
				
				reject(err);
			});
		});
	}
	
    signup(user) {
		
		if(!user.username)
		user.username = user.email;
        return new Promise((resolve, reject) => {
            this.http.post(this.serverPath + '/users', user)
			.subscribe(data => {
				resolve(data);
			}, err => {
				var error = err.json().error.details;
				//this.showErr(err);
				reject(err.json().error);
			});
		});
	}
	
    logout(message : any = ""){
		// return new Promise(resolve => {
			
		// 	var headers = new Headers();
        //     this.loadUserCredentials();
			
		// 	var tokenCreated : any = new Date(localStorage.getItem("created"));
		// 	var now : any = new Date();
		// 	var validity : any = parseInt(localStorage.getItem("ttl"));
			
		// 	// Return from here if user is not logged in or token expired.
		// 	if(!this.AuthToken || (now - tokenCreated) > validity){
		// 		this.destroyUserCredentials();
		// 		return resolve("Logout...");
		// 	}
			
		// 	// If valid token request to server for logout
        //     headers.append('Authorization', localStorage.getItem("token"));
			
		// 	console.log("logout called...");
		// 	this.http.post(this.serverPath + '/users/logout', {}, { headers : headers }).map(res =>res.json()).subscribe(data => {
		// 		this.destroyUserCredentials();
		// 		this.router.navigate(['/login']);
		// 		resolve(true);
		// 	}, err => {
		// 		this.destroyUserCredentials();
		// 		this.router.navigate(['/login']);
		// 		resolve(false);
		// 		//this.showErr(err);
		// 	});
		// })
		this.router.navigate(['/login']);
	}
	
  
    resetLimits(){
		this.pageItems = 10;
		this.skipItems = 0;
		this.dataApi = null;
	}
	
	getData(api, filter: any = null, pagination: any = null, scrollEvent = null){
		// this.sessionparse();
		return new Promise((resolve,reject) => {
			var params:any = filter ? filter : null;
			if(!params){
			}
			if(this.dataApi != api || pagination == 1){
				this.resetLimits();
			}
			if(pagination){
				this.dataApi = api;
				params.filter.limit = this.pageItems;
				params.filter.skip = this.skipItems;
				this.skipItems = this.pageItems * pagination;
			}
			let headers = new HttpHeaders();
			if( filter==null || filter==undefined || filter.token=='' || filter.token==undefined ){
				this.loadUserCredentials();
			}else{
				this.AuthToken=filter.token;
			}
			headers = headers.set('x-access-token',  this.AuthToken);
			// params.co=this.costCenterId;
			// params.uid=this.userid;
			// params.shop_id=this.shopid;
			this.http.get(this.serverPath + api, { headers : headers, params })
			.subscribe((data:any) => {
				
				if(data){
					resolve(data);
					if(scrollEvent && (data.length == 0 || data.length < this.pageItems)){
						scrollEvent.enable(false);
					}
					}else if(scrollEvent){
					resolve(data);
					scrollEvent.enable(false);
				}
				
			}, err => {
				reject(err);
				//this.showErr(err);
			});
		});
	}
	
	
	getConfig(name?){
		return new Promise((resolve,reject) => {
			this.http.get("/assets/json/config.json")
			.subscribe(data => {
				let config = name ? data[name] : data;
				resolve(config);
			}, err => {
				reject(err);
			});
		});
	}
	
	
	/* 
	showErr(err){
		let errMsg = "Unexpected Error!";
		err = err || {};
		let error = err.json ? err.json() : err; 
		if(err.status == 401){
			errMsg = "Authorization Required or You don't have permission.";
		}else if(err.status == 500){
			errMsg = "Internal Server Error";
		}else if(err.status == 404){
			errMsg = error.error.message;
		}else{
			errMsg = err;
		}
		Messenger().post({
			message: errMsg,
			type: 'error',
			showCloseButton: true,
		});
		console.log(error);
		console.log(errMsg);
	}
	
	showSuccess(message){
		Messenger().post({
			message: message,
			type: 'success',
			showCloseButton: true,
		});
	} */
	
	postData(api, input){
		return new Promise((resolve, reject) => {
			let headers = new HttpHeaders();
			
			if(!input.token){
				this.loadUserCredentials();
			}else{
				this.AuthToken=input.token;
			}
			headers = headers.set('x-access-token',  this.AuthToken);
			// headers.append('x-access-token', this.AuthToken);
			// console.log(headers);
			// headers = headers.set('Content-Type', 'application/json; charset=utf-8');
			// input.co=this.costCenterId;
			// input.uid=this.userid;
			// console.log(input.shopid);
			// if(input.shopid=='' || input.shopid==undefined){
			// 	input.shop_id=this.shopid;
			// }
			// console.log(input);
			this.http.post(this.serverPath + api, input, { headers : headers })
			.subscribe((response:any) => {
				if(response){
					response.id = response.id || null;
					// localStorage.setItem("userId", response.userId);
				}
				//this.showSuccess("Details Saved Successfully.");
				resolve(response);
				//console.log("Record Created Successfully.");
			}, err => {
				//this.showErr(err);
				reject(err);
			});
		});
	}
	putData(api ,input){
		return new Promise((resolve, reject) => {
			let headers = new HttpHeaders();
			if(!input.token){
				this.loadUserCredentials();
			}else{
				this.AuthToken=input.token;
			}
			headers = headers.set('x-access-token',  this.AuthToken);
            // headers.append('Authorization', this.AuthToken);
			// headers = headers.set('Content-Type', 'application/json; charset=utf-8');
			// this.loadUserCredentials();
			// headers.append('Authorization', this.AuthToken);
			// input.co=this.costCenterId;
			// input.uid=this.userid;
			// input.shop_id=this.shopid;
			this.http.post(this.serverPath + api  , input, { headers : headers })
			// .map((data:any) => data.json())
			.subscribe(data => {
				resolve(data);
				//this.showSuccess("Details Updated Successfully.");
			}, err => {
				//this.showErr(err);
				reject(err);
			});
		});
	}
	updateData(api, id, input){
		return new Promise((resolve, reject) => {
			let headers = new HttpHeaders();
            //console.log(this.AuthToken);
            // headers.append('Authorization', this.AuthToken);
			if(!input.token){
				this.loadUserCredentials();
			}else{
				this.AuthToken=input.token;
			}
			headers = headers.set('x-access-token',  this.AuthToken);
		
			this.http.put(this.serverPath + api + "/" + id, input, { headers : headers })
			.subscribe(data => {
				resolve(data);
				//this.showSuccess("Details Updated Successfully.");
			}, err => {
				//this.showErr(err);
				reject(err);
			});
		});
	}
	deleteData(api, id){
		return new Promise((resolve, reject) => {
			// var headers = new Headers();
			// this.loadUserCredentials();
			// headers.append('Authorization', this.AuthToken);
			let headers = new HttpHeaders();
			this.loadUserCredentials();
			console.log(this.AuthToken);
			headers = headers.set('x-access-token',  this.AuthToken);

			if(id!=""){
			var conf = confirm("Are you sure?");
			
			if(!conf){
				// return resolve();
			}
			
			this.http.delete(this.serverPath + api + "/" + id, { headers : headers })
			.subscribe(data => {
				resolve(data);
				alert("Record Deleted Successfully.");
			}, err => {
				//this.showErr(err);
				reject(err);
			});
			}
			else
			{
			this.http.delete(this.serverPath + api, { headers : headers })
			.subscribe(data => {
				resolve(data);
				//this.showSuccess("Record Deleted Successfully.");
			}, err => {
				//this.showErr(err);
				reject(err);
			});	
				
			}
		});
	}
	deleteExperience(api){
		return new Promise((resolve, reject) => {
			// var headers = new Headers();
			// this.loadUserCredentials();
			// headers.append('Authorization', this.AuthToken);
			let headers = new HttpHeaders();
            //console.log(this.AuthToken);
            // headers.append('Authorization', this.AuthToken);
			headers = headers.set('Content-Type', 'application/json; charset=utf-8');
			
			this.http.delete(this.serverPath + api , { headers : headers })
			.subscribe(data => {
				resolve(data);
			}, err => {
				//this.showErr(err);
				reject(err);
			});
		});
		
	}


	uploadFile(api , file, filter: any = null){
		return new Promise((resolve,reject) => {
			/* if(!file){ 
				reject("File Data not found");
			} */
			var params:any = filter ? filter : null;
			if(!params){
			}
			let headers = new HttpHeaders();
			// file = new Blob([
			// JSON.stringify({})
			// ], { type: 'application/json' });
			this.loadUserCredentials();
			headers.append('Authorization',this.AuthToken);
			
			var formData: any = new FormData();
			for(let i =0; i < file.length; i++){
				formData.append("name", file[i]);
			}
			//formData.append("name", file);
			this.http.post(this.serverPath + api,formData, { headers : headers, params })
			.subscribe(response => {
				resolve (response);
			}, err => {
				//this.showErr(err);
				reject(err);
			});
			
		})
		
	}
	
}
