import { Component, HostListener, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init();
  }

  header_variable:boolean=false;
  @HostListener("document:scroll")
  scrollfunction(){
    if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
      this.header_variable=true;
    }else{
      this.header_variable=false;
    }
  }

  showSearchBar:boolean=false;

  showSearchBarRow(){
    this.showSearchBar=true;
  }
  hideSearchBarRow(){
    this.showSearchBar=false;
  }
}
