import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {path : 'layout' , loadChildren:'./layout/layout.module#LayoutModule'},
  {path: '' , loadChildren : './webpages/webpages.module#WebpagesModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true,scrollPositionRestoration: 'top'  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


