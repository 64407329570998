import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBarComponent } from '../sharecomponent/mat-snack-bar/mat-snack-bar.component';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
 constructor(private snackBar: MatSnackBarComponent ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    console.log(error.status);
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } 
                   else if(error.status==401){
                        this.snackBar.openSnackBar('Authentication failed','Close','red-snackbar');
                    }else if(error.status==403){
                        console.log(error);
                        console.log(error.error.message);
                        let errormsg:any=error.error.message;
                        this.snackBar.openSnackBar(errormsg,'Close','red-snackbar');
                    }else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    }
                   // console.log(error);
                    return throwError(errorMessage);
                   
                })
            )
    }
}